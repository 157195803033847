<template>
  <div class="aboutGroup">
    <div class="contents">
      <div class="contentCenter">
        <h3 class="title" id="title">西安办公区</h3>
        <p class="lines">
          <span class="lineCenter"></span>
        </p>

        <div class="contextCenter">
          <baidu-map
            :center="center"
            :zoom="zoom"
            @ready="handler"
            style="height: 561px; width: 100%"
            @click="getClickInfo"
            :scroll-wheel-zoom="true"
          >
          </baidu-map>
          <div class="detail">
            <div class="detailCenter">
                <p
              style="
                font-size: 20px;
                font-weight: 600;
                color: #000000;
                margin-left: 44px;
                padding-top: 28px;
              "
            >
              西安有为信通软件科技有限公司
            </p>
            <div class="introduce">
              <p>Add：陕西省西安市高新区科技三路57号融城云谷C座3层</p>
              <p>Tel：029-81131332</p>
              <p>E-mail：sunxiaoqiang@uweic.com</p>
              <p>P.C.：710077029</p>
              <p>Fax：029-81131332</p>
              <p>Url：www.sxuweic.com</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import iconCar from "../../assets/image/contactUs/mapicon.png"; //以import的方式导入图片文件
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      center: { lng: 116.311286, lat: 39.832251 },
      zoom: 20,
    };
  },
  mounted: function () {
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(108.891594,34.228701);
      map.centerAndZoom(point, 18);

      // var circle = new BMap.Circle(point, 6, { strokeColor: '#6F44CD', strokeWeight: 6, strokeOpacity: 1, Color: '#FEE33D', fillColor: '#6F44CD' })
      // map.addOverlay(circle)
      const deviceSize = new BMap.Size(44, 61);
      const deviceIcon = new BMap.Icon(iconCar, deviceSize, {
        //会以base64的方式传参iconCar
        imageSize: deviceSize,
      });
      var marker = new BMap.Marker(point, {
        icon: deviceIcon,
      }); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
  },
};
</script>

<style scoped>
/* 主体内容开始 */
.contents {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/contactUs/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.contents .contentCenter {
  padding-top: 78px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .contentCenter .title {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: bold;
  color: #17171c;
}
.contents .contentCenter .lines {
  margin-bottom: 36px;
}
.contents .contentCenter .lines .lineCenter {
  width: 70px;
  height: 4px;
  background: #3c4047;
  border-radius: 2px;
  display: inline-block;
}
.contents .contentCenter .contextCenter {
  color: #333333;
  /* display: flex; */
  padding-top: 37px;
  flex-direction: column;
  /* justify-content: space-between; */
}
.contents .contentCenter .contextCenter .detail {
  box-shadow: 0 0 15px rgba(10, 10, 14, 0.2);
  height: 180px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  background-color: #fff;
}
.contents .contentCenter .contextCenter .detail .detailCenter{
  margin: 0 auto;
  width: 95%;
  height: 180px;
}
.introduce {
  margin-left: 44px;
  padding-top: 20px;
  width: 100%;
}

.introduce p {
  float: left;
  width: 33%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}
</style>